<script lang="ts" setup>
  interface IProps {
    horizontal?: boolean;
    appearance?: 'series' | 'continue';
  }
  const props = withDefaults(defineProps<IProps>(), {
    appearance: 'continue',
  });
</script>

<template>
  <component
    is="div"
    class="roulette-skeleton"
    :class="{ 'roulette-skeleton--horizontal': props.horizontal }"
    :data-appearance="props.appearance"
  >
    <div class="roulette-skeleton__item" v-for="index in 5" :key="index">
      <div class="roulette-skeleton__card"></div>
      <div class="roulette-skeleton__name">
        <v-skeleton size="small" />
        <v-skeleton size="small" w="100px" />
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
  .roulette-skeleton {
    --skeleton-width: 282px;
    --skeleton-height: 362px;
    --skeleton-radius: 32px;
    $parent: &;

    display: flex;
    overflow: hidden;
    gap: 24px;
    margin: 64px 0 56px 0;
    &__item {
      display: grid;
      gap: 16px;
    }
    &__card {
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.05) 100%), #000;
      width: var(--skeleton-width);
      height: var(--skeleton-height);
      border-radius: var(--skeleton-radius);
    }
    &__name {
      display: grid;
      gap: 8px;
    }
    &--horizontal {
      &[data-appearance='series'] {
        --skeleton-width: 325px;
        --skeleton-height: 200px;
        --skeleton-radius: 16px;
        @media (max-width: $retina) {
          --skeleton-width: 222px;
          --skeleton-height: 136px;
        }
      }
      &[data-appearance='continue'] {
        --skeleton-width: 384px;
        --skeleton-height: 272px;
        --skeleton-radius: 32px;
      }
    }
    @media (max-width: $retina) {
      --skeleton-width: 156px;
      --skeleton-height: 232px;
      --skeleton-radius: 16px;
    }
  }
</style>
